var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FormulateForm',{staticClass:"max-w-2xl mb-12 mx-auto",attrs:{"name":"Profile"},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasErrors = ref.hasErrors;
var isLoading = ref.isLoading;
return [_c('h2',{staticClass:"tg-mobile-header-3 md:tg-desktop-header-3 mb-7.5",domProps:{"textContent":_vm._s(_vm.$t('personal_information'))}}),_c('FormulateErrors'),_c('FormulateInput',{attrs:{"type":"text","name":"first_name","label":_vm.$t('first_name'),"show-required-label":true,"validation":"required:trim"}}),_c('FormulateInput',{attrs:{"type":"text","name":"last_name","label":_vm.$t('last_name'),"show-required-label":true,"validation":"required:trim"}}),_c('div',{staticClass:"grid grid-flow-col gap-4 my-4"},[_c('FormulateInput',{attrs:{"type":"text","name":"number","label":_vm.$t('number'),"outer-class":"m-0"}}),_c('FormulateInput',{attrs:{"type":"text","name":"box","label":_vm.$t('box'),"outer-class":"m-0"}})],1),_c('FormulateInput',{attrs:{"type":"autocomplete","name":"city","label":_vm.$t('location'),"placeholder":_vm.$t('placeholder_autocomplete')}}),_c('FormulateInput',{attrs:{"type":"email","name":"email","label":_vm.$t('email_label'),"show-required-label":true,"validation":"bail|required|email"},model:{value:(_vm.profile.email),callback:function ($$v) {_vm.$set(_vm.profile, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"profile.email"}}),_c('FormulateInput',{attrs:{"type":"dial_code_tel","name":"mobile","label":_vm.$t('mobile'),"show-required-label":true,"validation":"bail|required|phone"}}),_c('FormulateInput',{attrs:{"type":"dial_code_tel","name":"phone","label":_vm.$t('phone'),"validation":"bail|optional|phone"}}),_c('FormulateInput',{attrs:{"type":"date","name":"birth_date","label":_vm.$t('birth_date'),"placeholder":"YYYY-MM-DD","value":_vm.minEighteenYears.date,"max":_vm.minEighteenYears.date,"validation":[
        ['bail'],
        ['optional'],
        ['date', 'YYYY-MM-DD'],
        ['before', _vm.minEighteenYears.forValidation]
      ]},model:{value:(_vm.profile.birth_date),callback:function ($$v) {_vm.$set(_vm.profile, "birth_date", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"profile.birth_date"}}),_c('FormulateInput',{attrs:{"type":"select","name":"language","label":_vm.$t('preferred_language'),"placeholder":_vm.$t('select_language'),"options":_vm.langOptions}}),_c('h3',{staticClass:"tg-mobile-header-4 md:tg-desktop-header-4 my-7.5",domProps:{"textContent":_vm._s(_vm.$t('reset_your_password'))}}),_c('FormulateInput',{attrs:{"type":"password","name":"password1","autocomplete":"new-password","label":_vm.$t('new_password'),"value":"","validation":[
        ['bail'],
        ['optional'],
        ['min', 6, 'length'],
        [
          'matches',
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
        ]
      ],"validation-messages":{ matches: _vm.$t('password_strength_validation') }}}),_c('FormulateInput',{attrs:{"type":"password","name":"password2","autocomplete":"new-password","label":_vm.$t('confirm_new_password'),"value":"","show-password-button":false,"validation":"confirm:password1"}}),_c('p',[_vm._v(" "+_vm._s(_vm.$t('by_using_our_customer_portal'))+" "),_c('a',{staticClass:"tg-mobile-link-small md:tg-desktop-link link",attrs:{"href":"#","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('terms_of_use'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('and_the'))+" "),_c('a',{staticClass:"tg-mobile-link-small md:tg-desktop-link link",attrs:{"href":"https://www.dewaelevastgoedgroep.be/privacybeleid","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('privacy_policy'))+". ")])]),_c('div',{staticClass:"my-7.5"},[_c('FormulateErrors'),_c('FormulateInput',{attrs:{"type":"submit","disabled":hasErrors || isLoading,"outer-class":"my-0","input-class":[
          'fixed lg:static bottom-0 right-0 m-5 lg:m-0 rounded-full lg:rounded-md'
        ]}},[_c('i',{class:[
            'far mr-2',
            isLoading ? 'fa-spinner-third animate-spin' : 'fa-save'
          ]}),_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)]}}]),model:{value:(_vm.profile),callback:function ($$v) {_vm.profile=$$v},expression:"profile"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }