<template>
  <div>
    <FormulateForm
      #default="{ hasErrors, isLoading }"
      v-model="profile"
      name="Profile"
      class="max-w-2xl mb-12 mx-auto"
      @submit="submit"
    >
      <h2
        class="tg-mobile-header-3 md:tg-desktop-header-3 mb-7.5"
        v-text="$t('personal_information')"
      />
      <FormulateErrors />
      <FormulateInput
        type="text"
        name="first_name"
        :label="$t('first_name')"
        :show-required-label="true"
        validation="required:trim"
      />
      <FormulateInput
        type="text"
        name="last_name"
        :label="$t('last_name')"
        :show-required-label="true"
        validation="required:trim"
      />

      <div class="grid grid-flow-col gap-4 my-4">
        <FormulateInput
          type="text"
          name="number"
          :label="$t('number')"
          outer-class="m-0"
        />
        <FormulateInput
          type="text"
          name="box"
          :label="$t('box')"
          outer-class="m-0"
        />
      </div>
      <FormulateInput
        type="autocomplete"
        name="city"
        :label="$t('location')"
        :placeholder="$t('placeholder_autocomplete')"
      />

      <FormulateInput
        v-model.trim="profile.email"
        type="email"
        name="email"
        :label="$t('email_label')"
        :show-required-label="true"
        validation="bail|required|email"
      />
      <FormulateInput
        type="dial_code_tel"
        name="mobile"
        :label="$t('mobile')"
        :show-required-label="true"
        validation="bail|required|phone"
      />
      <FormulateInput
        type="dial_code_tel"
        name="phone"
        :label="$t('phone')"
        validation="bail|optional|phone"
      />

      <FormulateInput
        v-model.trim="profile.birth_date"
        type="date"
        name="birth_date"
        :label="$t('birth_date')"
        placeholder="YYYY-MM-DD"
        :value="minEighteenYears.date"
        :max="minEighteenYears.date"
        :validation="[
          ['bail'],
          ['optional'],
          ['date', 'YYYY-MM-DD'],
          ['before', minEighteenYears.forValidation]
        ]"
      />

      <FormulateInput
        type="select"
        name="language"
        :label="$t('preferred_language')"
        :placeholder="$t('select_language')"
        :options="langOptions"
      />

      <h3
        class="tg-mobile-header-4 md:tg-desktop-header-4 my-7.5"
        v-text="$t('reset_your_password')"
      />

      <FormulateInput
        type="password"
        name="password1"
        autocomplete="new-password"
        :label="$t('new_password')"
        value=""
        :validation="[
          ['bail'],
          ['optional'],
          ['min', 6, 'length'],
          [
            'matches',
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
          ]
        ]"
        :validation-messages="{ matches: $t('password_strength_validation') }"
      />
      <FormulateInput
        type="password"
        name="password2"
        autocomplete="new-password"
        :label="$t('confirm_new_password')"
        value=""
        :show-password-button="false"
        validation="confirm:password1"
      />

      <p>
        {{ $t('by_using_our_customer_portal') }}
        <a
          href="#"
          target="_blank"
          class="tg-mobile-link-small md:tg-desktop-link link"
        >
          {{ $t('terms_of_use') }}
        </a>
        {{ $t('and_the') }}
        <a
          href="https://www.dewaelevastgoedgroep.be/privacybeleid"
          target="_blank"
          class="tg-mobile-link-small md:tg-desktop-link link"
        >
          {{ $t('privacy_policy') }}.
        </a>
      </p>

      <div class="my-7.5">
        <FormulateErrors />
        <FormulateInput
          type="submit"
          :disabled="hasErrors || isLoading"
          outer-class="my-0"
          :input-class="[
            'fixed lg:static bottom-0 right-0 m-5 lg:m-0 rounded-full lg:rounded-md'
          ]"
        >
          <i
            :class="[
              'far mr-2',
              isLoading ? 'fa-spinner-third animate-spin' : 'fa-save'
            ]"
          />
          {{ $t('save') }}
        </FormulateInput>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import { updateUserProfile, passwordChange } from '@/services/apiService';
import { minEighteenYears, parsePhone, langOptions } from '@/helpers';
import { mapActions } from 'vuex';

export default {
  name: 'Profile',
  data() {
    return {
      minEighteenYears,
      langOptions,
      profile: {}
    };
  },
  created() {
    this.initProfile();
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('profile', ['fetchProfile', 'setUserLocale']),
    initProfile() {
      this.fetchProfile()
        .then(response => {
          this.profile = response.data;

          this.profile.password1 = '';
          this.profile.password2 = '';

          this.setUserLocale();
        })
        .catch(() => {});
    },
    async saveProfileData(data) {
      let profile = { ...data };
      profile.city = data.city.id ? data.city.id : '';
      delete profile.password1;
      delete profile.password2;
      await updateUserProfile(profile);

      if (data.password1?.length && data.password2?.length > 0) {
        let passwordData = {
          password1: data.password1,
          password2: data.password2
        };
        await passwordChange(passwordData);
        this.logout();
        this.$router.push({ name: 'AuthLogin' });
      }
    },
    submit(data) {
      let profile = { ...data };
      profile.mobile = parsePhone(profile.mobile);
      if (profile.phone) profile.phone = parsePhone(profile.phone);

      return this.saveProfileData(profile)
        .then(() => {
          this.$store.dispatch('snackbar/show', {
            type: 'success',
            message: this.$t('success_details_submit')
          });
          this.initProfile();
        })
        .catch(error => {
          this.$formulate.handle(error, 'Profile');
        });
    }
  }
};
</script>
